import { erf } from "mathjs";
const math = require('mathjs');
const ss = require('simple-statistics');
const rev_order = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0];
const threshold = 5;


export const checkPersonality = (org) => {
  return org?.organization?.services_enabled?.find((f) => f?.id === 21)
    ?.enabled;
};

export const convert_personality_to_categories = (resp) => {
  resp?.map((item) => {
    item?.responses?.map((r) => {
      if (Object.keys(r?.response?.survey_personality)?.length > 0) {
        let personality = calculate_personality_categories(
          r?.response?.survey_personality
        );
        r?.response?.categories?.push(...personality);
      }

      return r;
    });
  });

  return resp;
};

const personality_norms = [
  [4.49, 1.59],
  [6.19, 0.89],
  [5.33, 1.18],
  [5.48, 1.26],
  [5.84, 0.96],
];

const personality_factors = [
  [1, 6, 6],
  [3, 8, 8],
  [2, 7, 2],
  [4, 9, 4],
  [5, 10, 10],
];

const reverse = {
  1: 7,
  2: 6,
  3: 5,
  4: 4,
  5: 3,
  6: 2,
  7: 1,
};

const personality_names = [
  "Extraversion",
  "Conscientiousness",
  "Agreeableness",
  "Neuroticism",
  "Openess",
];

export const check_comments = (resp, get_employee) => {
  let comm = false;
  if (get_employee) {
    let user_info = get_employee?.userEmp;

    if (
      !user_info ||
      (!user_info.read_feedback && user_info.account_type_text != "Admin")
    ) {
      return false;
    }

    // if(questionStructure){
    //   if(!("comments" in questionStructure)){
    //     return false
    //   }
    //   else if(questionStructure.comments.length<1){
    //     return false
    //   }
    // b2979f0d-89d9-48e7-837b-d0ebff6f6d41
    // }

    for (var i = 0; i < resp.responses.length; i++) {
      if ("comments" in resp.responses[i] || "feedback" in resp.responses[i]) {
        comm = true;
        break;
      }
    }
  }

  return comm;
};

const zptile = (z_score) => {
  return 0.5 * (erf(z_score / 2 ** 0.5) + 1);
};
const get_personality_percentile = (p_score, id) => {
  const norm = personality_norms[id];
  let z_score = (p_score - norm[0]) / norm[1];
  return Math.floor(zptile(z_score) * 100);
};

const calculate_personality = (data, id) => {
  let factors = personality_factors[id];
  let s1 = Math.round(data[factors[0]].response);
  let s2 = Math.round(data[factors[1]].response);
  let score1 = factors[2] === factors[0] ? reverse[s1] : s1;
  let score2 = factors[2] === factors[1] ? reverse[s2] : s2;
  return get_personality_percentile(
    Math.round(((score1 + score2) / 2) * 100) / 100,
    id
  );
};

const calculate_personality_categories = (data) => {
  let pers = personality_norms.map((item, i) => calculate_personality(data, i));
  return pers.map((item, i) => {
    let response = item < 33 ? "0" : item < 67 ? "1" : "2";
    return { id: personality_names[i], priority: "personality", response };
  });
};

export const generate_chart_standards_data = (
  data,
  standards,
  navigation,
  categories,
  data_type,
  outcomeStandards,
  rule
) => {
  const parent_category = navigation?.category?.id;
  const category_options = categories?.categories?.find(
    (f) => f.id === parent_category
  )?.options;
  let resp = [];

  category_options?.map((option) => {
    let filtered_data = data.filter(
      (f) =>
        f.categories.find((f) => f.id === parent_category)?.response ==
        option.id
    );

    if (filtered_data.length <= rule) {
      return;
    }

    let overall_average = filtered_data
      .map((f) => f.questions.map((q) => q.response).flat())
      .flat();
    let overall_standard = standards?.response?.overall?.average;

    if (data_type.type === "outcome") {
      let outcome = navigation?.outcome?.id;
      overall_average = filtered_data
        .map((f) =>
          f.employee_outcomes?.responses
            ?.filter((f) => f.q === outcome)
            .map((f) => f.response)
        )
        .flat()
        .filter((f) => f);
      overall_standard = outcomeStandards?.[data_type.id]?.overall_average;
    }

    let name = option.name;
    let option_id = option.id;
    let average = average_array(overall_average);

    const diff = (average * 10).toFixed(0) - (overall_standard * 10).toFixed(0);

    if (average) {
      resp.push({
        average: average,
        diff: diff,
        name: name,
        option_id: option_id,
      });
    }
  });

  return resp;
};

const average_array = (arr) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length;
};

export const generate_chart_factor_standards = (
  data,
  standards,
  navigation,
  categories,
  data_type,
  questions,
  rule
) => {
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let filtered_data = data.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );

  if (navigation.sub_category?.name == "Factor overview") {
    filtered_data = data;
  }

  if (filtered_data.length <= rule) {
    return;
  }

  let resp = [];
  questions.dimensions.map((dimension, d) => {
    dimension.factors.map((factor, i) => {
      let overall_average = filtered_data
        .map((f) =>
          f.questions
            .filter((f) => f.id == d && f.factor == i)
            .map((q) => q.response)
            .flat()
        )
        .flat();
      let name = factor.title;
      let average = average_array(overall_average);
      let standard = standards.response.pillars.find(
        (f) => f.id == d && f.factor == i
      ).average;

      let diff = average  - standard;
      let option_id = factor.id;

      if (average) {
        resp.push({
          average: average,
          diff: diff*10,
          name: name,
          option_id: option_id,
          dimension: d,
        });
      }
    });
  });

  return resp;
};

// Used for hockeycanada
export const processCommentQuestions = (qData, feedback, navigation) => {
  const parentCategory = navigation?.category?.id;
  const subCategory = navigation?.sub_category?.id;

  let filteredData = subCategory
    ? feedback.filter(
        (item) =>
          item.categories.find((cat) => cat.id === parentCategory)?.response ===
          subCategory
      )
    : feedback;
      
    let resp = [];

  if (!filteredData) {
      return resp;
  }


filteredData.forEach((feedbackItem) => {
  // Extract the feedback and employee data
  const employeeId = feedbackItem.employee;
  const comments = feedbackItem.comments;

  // Iterate over each feedback comment
  comments.forEach((comment, index) => {
    const correspondingQuestion = qData[index]; // Get the corresponding question from qData

    // Create the parsed feedback structure
    resp.push({
      type: "Comment", // Assuming type is "Comment" for all
      question: correspondingQuestion.question, // Map the question
      feedback: comment.feedback, // User feedback
      id: correspondingQuestion.id, // The question ID
      ques_order: comment.ques_sort_order, // Sort order from feedback
      employee: employeeId, // Employee ID
    });
  });
});
  
  return resp;
}

export const process_feedback_comments = (data, navigation) => {
  const parentCategory = navigation?.category?.id;
  const subCategory = navigation?.sub_category?.id;

  let filteredData = subCategory
    ? data.filter(
        (item) =>
          item.categories.find((cat) => cat.id === parentCategory)?.response ===
          subCategory
      )
    : data;

  let resp = [];

  if (!filteredData) {
    return resp;
  }

  filteredData.forEach((item, idx) => {
    const comments = item.comments;

    comments?.forEach((comment) => {
      // Check if the response is not "N/A" and not empty
      const validResponse =
        comment.response && comment.response.trim().toLowerCase() !== "n/a";

      if (validResponse) {
        resp.push({
          type: "Comment",
          question: comment.question,
          feedback: comment.response,
          id: comment.id,
          ques_order: comment.ques_order,
          employee: item?.employee,
        });
      }
    });
  });

  return resp.sort((a, b) => a.ques_order - b.ques_order);
};

export const process_feedback_data = (data, navigation, unfiltered) => {
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let filtered_data = sub_category
    ? data.filter(
        (f) =>
          f.categories.find((f) => f.id === parent_category)?.response ==
          sub_category
      )
    : data;

    console.log(filtered_data)

  let resp = [];

  if (!filtered_data) {
    return resp;
  }

  filtered_data.map((item,idx) => {
    let filteredFeedback;

    let outcomesQuestions = item?.feedback_builder?.responses;
    // If there are outcomes questions, and the navigation is set to show all OR outcomes.
    if (
      navigation?.outcome?.name === "All Feedback" ||
      navigation?.outcome?.id === 0 ||
      unfiltered
    ) {
      outcomesQuestions?.forEach((question, idx) => {
        // ensure question?.response is not empty, remove whitespace in case the response was just an empty space
        let emptyResponse = question?.response?.replace(/\s/g, "").length === 0;
        if (
          !question?.skipped &&
          !emptyResponse &&
          question?.response !== null
        ) {
          let q = question?.q;
          if (!q) {
            // if there is no q associated to the particular question, use
            // the followUpQuestion[0] to find the id of the question its connected to
            q = outcomesQuestions.find(
              (f) => f.id === question.followUpQuestions?.[0]
            )?.q;
          }
          let score = item.employee_outcomes?.responses?.find(f=>f.q ==q && f.s == question.s)?.response;
          score = score ? score : 10;

          resp.push({
            type: "Outcome",
            score: score,
            question: question.question,
            feedback: question.response,
            outcome: q,
            id: idx,
            employee: item?.employee,
            nlp:question?.nlp_classification
          });
        }
      });
    }

    if (navigation?.outcome?.type === "outcome" || unfiltered) {
      outcomesQuestions?.forEach((question, idx) => {
        let emptyResponse =
          question?.response?.replace(/\s/g, "").length < 2 ||
          question?.response === null ||
          question?.question?.length === 0;
        if (!question?.skipped || !emptyResponse) {
          if (
            Number(question?.q) === Number(navigation?.outcome?.id) &&
            question?.response != null
          ) {
            let score = item.employee_outcomes?.responses?.find(f=>f.q ==question.q && f.s == question.s)?.response;
            score = score ? score : 10;

            resp.push({
              type: "Outcome",
              score: score,
              question: question.question,
              feedback: question.response,
              outcome: question?.q,
              id: idx,
              employee: item?.employee,
              nlp:question?.nlp_classification
            });
          }
        }
      });
    }

    // If we are only showing outcomes, remove these.
    if (navigation?.outcome?.type !== "outcome" || unfiltered) {
      if (item?.ml_feedback?.feedback?.length > 0) {
        filteredFeedback = navigation?.factor?.id
          ? item?.ml_feedback?.feedback?.filter(
              (f) =>
                Number(f?.dimension) ===
                  Number(navigation?.factor?.dimension) &&
                Number(f?.factor) === Number(navigation?.factor?.id - 1)
            )
          : item?.ml_feedback?.feedback;
      } else {
        filteredFeedback = navigation?.factor?.id
          ? item?.feedback?.filter(
              (f) =>
                Number(f?.dimension) ===
                  Number(navigation?.factor?.dimension) &&
                Number(f?.factor) === Number(navigation?.factor?.id - 1)
            )
          : item?.feedback;
      }
      if (filteredFeedback && filteredFeedback.length > 0) {
        filteredFeedback.map((question, idx) => {
          let formatting_issue = ![0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(
            question.response
          );
          let index = resp?.length + 1;

          const hasFollowUp =
            filteredFeedback[idx + 1]?.dimension == question.dimension &&
            filteredFeedback[idx + 1]?.factor == question.factor &&
            filteredFeedback[idx + 1]?.employee == question.employee;
          const isFollowUp =
            filteredFeedback[idx - 1]?.dimension == question.dimension &&
            filteredFeedback[idx - 1]?.factor == question.factor &&
            filteredFeedback[idx - 1]?.employee == question.employee;
          let follow_up_question = hasFollowUp
            ? filteredFeedback[idx + 1]
            : null;

          if (
            question?.feedback?.replace(/\s/g, "").length !== 0 &&
            !isFollowUp
          ) {
            if (formatting_issue) {
              resp.push({
                type: "Culture",
                question: question.question,
                score: 10,
                feedback: question?.response,
                follow_up_question: follow_up_question?.prompt_question,
                follow_up_response: follow_up_question?.feedback,
                dimension: question.dimension,
                factor: question.factor,
                id: index,
                employee: item?.employee,
                nlp: question?.nlp_classification,
                hasFollowUp,
              });
            } else {
              resp.push({
                type: "Culture",
                question: question.question,
                score: question.response,
                feedback: question.feedback,
                follow_up_question: follow_up_question?.prompt_question,
                follow_up_response: follow_up_question?.feedback,
                dimension: question.dimension,
                factor: question.factor,
                id: index,
                employee: item?.employee,
                nlp: question?.nlp_classification,
                hasFollowUp,
              });
            }
          }
        });
      }
    }
  });

  return resp.sort((a, b) => a.score - b.score);
};

export const generate_outcome_question_standards = (
  data,
  standards,
  navigation,
  outcomeQuestions,
  rule
) => {
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.factor?.id;

  let filtered_data = data.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ===
      sub_category
  );

  if (filtered_data.length <= rule) {
    return;
  }

  return outcomeQuestions?.questions
    ?.find((q) => Number(q?.id) === Number(navigation?.outcome?.id))
    ?.questions.map((question, idx) => {
      let overall_average = filtered_data
        .map((f) =>
          f.employee_outcomes?.responses
            ?.filter((f) => {
              return (
                Number(f.q) === Number(question.q) &&
                Number(f.s) === Number(question?.s)
              );
            })
            .map((f) => f.response)
        )
        .flat();

      let feedback = filtered_data
        .map((f) =>
          f.feedback_builder?.responses
            ?.filter((f) => {
              return Number(f.q) === Number(question.q);
            })
            .map((f) => f.response)
        )
        .flat();

      let name = question.question;
      let average = average_array(overall_average);

      let standard = standards[question?.q].items.find(
        (item) => Number(item.q) === Number(question?.s)
      ).average;

      return {
        average,
        standard,
        name,
        feedback,
      };
    });
};

export const generate_question_standards = (
  data,
  standards,
  navigation,
  categories,
  questions,
  rule
) => {
  const factor = navigation.factor.id - 1;
  const dimension = navigation.factor.dimension;

  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let filtered_data = data.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );

  if (navigation.sub_category?.name == "Factor overview") {
    filtered_data = data;
  }

  if (filtered_data.length <= rule) {
    return;
  }

  // iterate on the questions object and filter for each question responses to get the average
  return questions.dimensions[dimension].factors[factor].questions.map(
    (question, idx) => {
      let overall_average = filtered_data
        .map((f) =>
          f.questions
            .filter(
              (f) => f.id == dimension && f.factor == factor && f.q == idx
            )
            .map((q) => q.response)
            .flat()
        )
        .flat();

      let feedback = filtered_data
        .map((f) =>
          f.feedback
            .filter((f) => f.dimension == dimension && f.factor == factor)
            .map((f) => f.feedback)
        )
        .flat();

      let name = question.q;
      let average = average_array(overall_average);
      let q_id = question?.old_id ? question.old_id : idx;

      let standard = standards.response.pillars.find(
        (f) => f.factor == factor && f.id == dimension
      ).overall[q_id];

      return {
        average,
        standard,
        name,
        feedback,
      };
    }
  );
};

const get_scores = (responses, questions) => {
  let total = [];
  questions.dimensions.map((d) => {
    d.factors.map((f) => {
      let resps = responses
        .map((r) => r.filter((x) => x.id == d.id - 1 && x.factor == f.id - 1))
        .map((r) => r.map((q) => check_reverse_score(q, questions)))
        .flat();

      let value = average(resps);
      total.push({ value, d: d.id - 1, factor: f.id - 1, n: resps.length });
    });
  });

  return total;
};

const average_1_layer = (array) => {
  let dimensions = [];
  array.map((item) => {
    if (!dimensions.includes(item.d)) {
      dimensions.push(item.d);
    }
  });

  let averages = dimensions.map((d) => {
    let total = 0;
    let n = 0;
    array
      .filter((f) => f.d == d)
      .map((item) => {
        total += item.value !== null ? item.value * item.n : 0;
        n += item.value != null ? item.n : 0;
      });

    if (!total) {
      return 0;
    }
    return total / n;
  });

  return average(averages);
};

const get_scores_below_threshold = (responses, questions, s = null) => {
  if (s) {
    responses = responses.map((i) =>
      i.filter((f) => f.id == s.d && f.factor == s.factor)
    );
  }
  return responses
    .map((i) => average(get_scores([i], questions).map((s) => s.value)))
    .filter((f) => f < threshold).length;
};

const check_reverse_score = (resp, questions) => {
  let reverse =
    questions.dimensions[resp.id].factors[resp.factor]?.questions[resp.q]
      ?.reverse;
  let response = reverse ? rev_order[Math.floor(resp.response)] : resp.response;
  return response;
};

const average = (array) => {
  let total = 0;
  let n = 0;
  array.map((item) => {
    total += item != null ? item : 0;
    n += item != null ? 1 : 0;
  });

  if (!total) {
    return 0;
  }
  return total / n;
};

function get_outcome_scores(scores, outcomeQ, outcomeStandards) {
  return outcomeQ?.questions
    .map((q) => {
      let responses = scores
        .map((r) => r.responses?.find((f) => f.q == q.id))
        .filter((f) => f);

      if (responses.length > 0) {
        let value = average(responses.map((r) => r.response));
        return {
          average: value,
          id: q.id,
          name: q.name,
        };
      }
    })
    .filter((f) => f);
}

const calculate_blob_data = (
  data,
  last,
  category,
  outcomeQ,
  outcomeStandards,
  questions,
  standards,
  priority
) => {
  let data_blob = [];
  category?.options.map((opt) => {
    let filtered = data
      .filter(
        (f) =>
          f.categories.find((f) => f.priority == priority)?.response == opt.id
      )
      .map((item) => item.questions);

    let feedback = data
      .filter(
        (f) =>
          f.categories.find((f) => f.priority == priority)?.response == opt.id
      )
      .map((item) => item.feedback)
      ?.flat();

    let outcomes = data
      .filter(
        (f) =>
          f.categories.find((f) => f.priority == priority)?.response == opt.id
      )
      .map((item) => item.employee_outcomes);

    let outcomesFeedback = data
      .filter(
        (f) =>
          f.categories.find((f) => f.priority == priority)?.response == opt.id
      )
      .map((item) => item.feedback_builder?.responses)
      ?.filter((f) => f);

    let last_outcomes = last
      .filter(
        (f) =>
          f.categories.find((f) => f.priority == priority)?.response == opt.id
      )
      .map((item) => item.employee_outcomes);

    let last_data = last
      .filter(
        (f) =>
          f.categories.find((f) => f.priority == priority)?.response == opt.id
      )
      .map((item) => item.questions);

    let outcome_scores = [];
    if (outcomes.length > 0) {
      outcome_scores = get_outcome_scores(outcomes, outcomeQ, outcomeStandards);
    }

    if (filtered.length > 1) {
      let scores = get_scores(filtered, questions);
      let last_scores = get_scores(last_data, questions);
      let standard_average = standards?.response.overall.average;
      let total = average_1_layer(scores);
      let last_total = average_1_layer(last_scores);
      let thresh = get_scores_below_threshold(filtered, questions);
      let diff = (total / standard_average - 1) * 100;
      let change = last_total ? (total - last_total) * 10 : null;

      let effect = change ? Math.abs(change) * diff : diff * filtered.length;

      data_blob.push({
        feedback: feedback,
        option1: opt,
        diff,
        factor: null,
        score: total,
        thresh,
        n: filtered.length,
        effect,
        type: "culture",
        change,
      });
    }
  });

  return data_blob.sort((a, b) => a.effect - b.effect);
};

const sort_factors_by_correlation = (correlations, questions) => {
  let factors = [];
  questions?.dimensions?.map((dimension, index) => {
    dimension.factors.map((factor) => {
      factor["dimension"] = index;
      factor["score"] = correlations.map((item) => {
        return item.data.find(
          (i) => i.factor === factor.id - 1 && i.dimension == index
        )?.correlation;
      });
      factors.push(factor);
    });
  });

  return factors.sort((a, b) => {
    // Calculate the sum of scores for object 'a'
    const sumA = a.score.reduce((acc, curr) => acc + curr, 0);

    // Calculate the sum of scores for object 'b'
    const sumB = b.score.reduce((acc, curr) => acc + curr, 0);

    // Sort in descending order by the sum of scores
    return sumB - sumA;
  });
};

export const generate_insights2 = (
  data,
  last,
  core_data,
  outcomeQ,
  outcomeStandards,
  navigation
) => {
  const categories = core_data?.categories?.[0];
  const questions = core_data?.questions;
  const standards = core_data?.standards;
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let data_blob = [];

  // Determine if we are going to look at only the primary and secondary categories separately
  // or together??
  const separated = true;
  let primary_category = categories?.categories.find(
    (f) => f.priority === "primary"
  );
  let secondary_category = categories?.categories.find(
    (f) => f.priority === "secondary"
  );

  let correlations = [];
  outcomeQ?.questions.map((item, index) => {
    const correlation_data = calculate_correlations([data], item.id);
    correlations.push({
      title: item.name,
      data: correlation_data,
    });
  });

  let factors = sort_factors_by_correlation(correlations, questions);

  if (separated) {
    data_blob = [
      ...data_blob,
      ...calculate_blob_data(
        data,
        last,
        primary_category,
        outcomeQ,
        outcomeStandards,
        questions,
        standards,
        "primary"
      ),
    ];
    data_blob = [
      ...data_blob,
      ...calculate_blob_data(
        data,
        last,
        secondary_category,
        outcomeQ,
        outcomeStandards,
        questions,
        standards,
        "secondary"
      ),
    ];
  }

  return data_blob.sort((a, b) => a.effect - b.effect);
};

const getImpactScore = (score,n,orgTotalScores,orgTotal,standard_average) => {
  
  const group_total = score * n
  const overall_total = orgTotalScores * orgTotal

  const rest_total = overall_total - group_total
  const rest_n = orgTotal - n

  const rest_average = (rest_total + standard_average*n) / (rest_n + n)

  const diff =  (orgTotalScores / rest_average - 1 )*100

  return diff
}

export const generate_insights3 = (
  data,
  last,
  core_data,
  outcomeQ,
  outcomeStandards,
  navigation
) => {
  const categories = core_data?.categories?.[0];
  const questions = core_data?.questions;
  const standards = core_data?.standards;
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  if(!questions){
    return []
  }

  let data_blob = [];
  let dataFiltered;
  let lastDataFiltered;

  dataFiltered = sub_category
    ? data.filter(
        (f) =>
          f.categories.find((f) => f.id === parent_category)?.response ==
          sub_category
      )
    : data;

  lastDataFiltered = sub_category
    ? last.filter(
        (f) =>
          f.categories.find((f) => f.id === parent_category)?.response ==
          sub_category
      )
    : last;

    const orgTotal = dataFiltered.length
    const orgData = dataFiltered.map((f) => f.questions);
    const org_scores = get_scores(orgData, questions);
    const orgTotalScores = average_1_layer(org_scores);

  categories?.categories.map((item) => {
    if (item?.demographic) {
      return;
    }

    item.options.map((opt) => {
      let filtered = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.questions);

     

      let feedback = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.feedback)
        ?.flat();

      let outcomes = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.employee_outcomes);

      let outcomesFeedback = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.feedback_builder?.responses)
        ?.filter((f) => f);

      let last_outcomes = lastDataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.employee_outcomes);

      let last_data = lastDataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.questions);

      let outcome_scores = [];
      if (outcomes.length > 0) {
        outcome_scores = get_outcome_scores(
          outcomes,
          outcomeQ,
          outcomeStandards
        );
      }

      let last_outcome_scores = [];
      if (last_outcomes.length > 0) {
        last_outcome_scores = get_outcome_scores(
          last_outcomes,
          outcomeQ,
          outcomeStandards
        );
      }

      if (
        navigation?.outcome?.name === "All Feedback" ||
        navigation?.outcome?.id === 0 ||
        navigation?.outcome?.type !== "outcome"
      ) {
        if (filtered.length > 1) {
          let scores = get_scores(filtered, questions);
          let last_scores = get_scores(last_data, questions);
          let standard_average = standards?.response?.overall?.average;
          let total = average_1_layer(scores);
          let last_total = average_1_layer(last_scores);
          let thresh = get_scores_below_threshold(filtered, questions);
          let diff = (total / standard_average - 1) * 100;
          let effect = diff * filtered.length;
          let impact = getImpactScore(total,filtered.length,orgTotalScores,orgTotal,standard_average)
          let change = last_total ? total - last_total : null;
          data_blob.push({
            feedback: feedback,
            category1: item.id,
            option1: opt,
            diff,
            factor: null,
            score: total,
            thresh,
            n: filtered.length,
            effect,
            impact,
            type: "culture",
            change,
          });
        }
      }

      // if (
      //   navigation?.outcome?.name === "All Feedback" ||
      //   navigation?.outcome?.id === 0 ||
      //   navigation?.outcome?.type === "outcome"
      // ) {
      //   if (outcome_scores?.length > 0) {
      //     outcome_scores.map((s) => {
      //       if (navigation?.outcome?.type === "outcome") {
      //         if (Number(s.id) !== Number(navigation?.outcome?.id)) {
      //           return;
      //         }
      //       }
      //       const lastOutcome = last_outcome_scores.find((f) => f.id === s.id);
      //       const standard_average = outcomeStandards
      //         ? outcomeStandards[s.id]?.overall_average
      //         : 0;
      //       let diff = (s.average / standard_average - 1) * 100;
      //       let effect = diff * filtered.length;
      //       data_blob.push({
      //         feedback: outcomesFeedback,
      //         category1: item.id,
      //         option1: opt,
      //         n: filtered.length,
      //         type: "outcome",
      //         score: s.average,
      //         id: s.id,
      //         name: s.name,
      //         change: lastOutcome ? s.average - lastOutcome.average : null,
      //         diff,
      //         effect,
      //       });
      //     });
      //   }
      // }
    });
  });
  return data_blob.sort((a, b) => a.impact - b.impact);
};

export const generate_insights = (
  data,
  last,
  core_data,
  outcomeQ,
  outcomeStandards,
  navigation
) => {
  const categories = core_data?.categories?.[0];
  const questions = core_data?.questions;
  const standards = core_data?.standards;
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let data_blob = [];
  let dataFiltered;
  let lastDataFiltered;

  dataFiltered = sub_category
    ? data.filter(
        (f) =>
          f.categories.find((f) => f.id === parent_category)?.response ==
          sub_category
      )
    : data;

  lastDataFiltered = sub_category
    ? last.filter(
        (f) =>
          f.categories.find((f) => f.id === parent_category)?.response ==
          sub_category
      )
    : last;

  categories?.categories.map((item) => {
    if (item?.demographic) {
      return;
    }

    item.options.map((opt) => {
      let filtered = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.questions);

      let feedback = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.feedback)
        ?.flat();

      let outcomes = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.employee_outcomes);

      let outcomesFeedback = dataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.feedback_builder?.responses)
        ?.filter((f) => f);

      let last_outcomes = lastDataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.employee_outcomes);

      let last_data = lastDataFiltered
        .filter(
          (f) => f.categories.find((f) => f.id == item.id)?.response == opt.id
        )
        .map((item) => item.questions);

      let outcome_scores = [];
      if (outcomes.length > 0) {
        outcome_scores = get_outcome_scores(
          outcomes,
          outcomeQ,
          outcomeStandards
        );
      }

      let last_outcome_scores = [];
      if (last_outcomes.length > 0) {
        last_outcome_scores = get_outcome_scores(
          last_outcomes,
          outcomeQ,
          outcomeStandards
        );
      }

      if (
        navigation?.outcome?.name === "All Feedback" ||
        navigation?.outcome?.id === 0 ||
        navigation?.outcome?.type !== "outcome"
      ) {
        if (filtered.length > 1) {
          let scores = get_scores(filtered, questions);
          let last_scores = get_scores(last_data, questions);
          let standard_average = standards?.response?.overall?.average;
          let total = average_1_layer(scores);
          let last_total = average_1_layer(last_scores);
          let thresh = get_scores_below_threshold(filtered, questions);
          let diff = (total / standard_average - 1) * 100;
          let effect = diff * filtered.length;
          let change = last_total ? total - last_total : null;
          data_blob.push({
            feedback: feedback,
            category1: item.id,
            option1: opt,
            diff,
            factor: null,
            score: total,
            thresh,
            n: filtered.length,
            effect,
            type: "culture",
            change,
          });
        }
      }

      if (
        navigation?.outcome?.name === "All Feedback" ||
        navigation?.outcome?.id === 0 ||
        navigation?.outcome?.type === "outcome"
      ) {
        if (outcome_scores?.length > 0) {
          outcome_scores.map((s) => {
            if (navigation?.outcome?.type === "outcome") {
              if (Number(s.id) !== Number(navigation?.outcome?.id)) {
                return;
              }
            }
            const lastOutcome = last_outcome_scores.find((f) => f.id === s.id);
            const standard_average = outcomeStandards
              ? outcomeStandards[s.id]?.overall_average
              : 0;
            let diff = (s.average / standard_average - 1) * 100;
            let effect = diff * filtered.length;
            data_blob.push({
              feedback: outcomesFeedback,
              category1: item.id,
              option1: opt,
              n: filtered.length,
              type: "outcome",
              score: s.average,
              id: s.id,
              name: s.name,
              change: lastOutcome ? s.average - lastOutcome.average : null,
              diff,
              effect,
            });
          });
        }
      }
    });
  });
  return data_blob.sort((a, b) => a.effect - b.effect);
};

export const generate_chart_change_data = (
  data,
  last,
  navigation,
  categories,
  data_type,
  rule
) => {
  const parent_category = navigation?.category?.id;
  const category_options = categories?.categories?.find(
    (f) => f.id === parent_category
  )?.options;
  let resp = [];

  category_options?.map((option) => {
    let filtered_data = data.filter(
      (f) =>
        f.categories.find((f) => f.id === parent_category)?.response ==
        option.id
    );
    let overall_average = filtered_data
      .map((f) => f.questions.map((q) => q.response).flat())
      .flat();

    let filtered_data_last = last.filter(
      (f) =>
        f.categories.find((f) => f.id === parent_category)?.response ==
        option.id
    );

    if (filtered_data.length <= rule || filtered_data_last.length <= rule) {
      return;
    }
    let overall_average_last = filtered_data_last
      .map((f) => f.questions.map((q) => q.response).flat())
      .flat();

    if (data_type.type === "outcome") {
      let outcome = navigation?.outcome?.id;
      overall_average = filtered_data
        .map((f) =>
          f.employee_outcomes?.responses
            ?.filter((f) => f.q === outcome)
            .map((f) => f.response)
        )
        .flat()
        .filter((f) => f);
      overall_average_last = filtered_data_last
        .map((f) =>
          f.employee_outcomes?.responses
            ?.filter((f) => f.q === outcome)
            .map((f) => f.response)
        )
        .flat()
        .filter((f) => f);
    }

    let name = option.name;
    let option_id = option.id;
    let average = average_array(overall_average);
    let average_last = average_array(overall_average_last);

    let diff = (average * 10).toFixed(0) - (average_last * 10).toFixed(0);
    if (average && average_last) {
      resp.push({
        average: average,
        diff: diff,
        name: name,
        option_id: option_id,
      });
    }
  });

  return resp;
};

export const generate_chart_factor_changes = (
  data,
  last,
  navigation,
  questions,
  rule
) => {
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let filtered_data = data.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );

  if (navigation.sub_category?.name == "Factor overview") {
    filtered_data = data;
  }

  if (filtered_data.length <= rule) {
    return;
  }

  let resp = [];
  questions.dimensions.map((dimension, d) => {
    dimension.factors.map((factor, i) => {
      let overall_average = filtered_data
        .map((f) =>
          f.questions
            .filter((f) => f.id == d && f.factor == i)
            .map((q) => q.response)
            .flat()
        )
        .flat();
      let name = factor.title;
      let average = average_array(overall_average);

      let overall_last_average = last.filter(
        (f) =>
          f.categories.find((f) => f.id === parent_category)?.response ==
          sub_category
      );

      if (navigation.sub_category?.name == "Factor overview") {
        overall_last_average = last;
      }

      if (overall_last_average.length <= rule) {
        return;
      }

      let last_average = overall_last_average
        .map((f) =>
          f.questions
            .filter((f) => f.id == d && f.factor == i)
            .map((q) => q.response)
            .flat()
        )
        .flat();

      let average_last = average_array(last_average);

      let diff = (average * 10).toFixed(0) - (average_last * 10).toFixed(0);

      let option_id = factor.id;

      if (average) {
        resp.push({
          average: average,
          diff: diff,
          name: name,
          option_id: option_id,
          dimension: d,
        });
      }
    });
  });

  return resp;
};

export const generate_question_changes = (
  data,
  last,
  navigation,
  questions,
  rule
) => {
  const factor = navigation.factor.id - 1;
  const dimension = navigation.factor.dimension;

  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.sub_category?.id;

  let filtered_data = data.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );


  if (navigation.sub_category?.name == "Factor overview") {
    filtered_data = data;
  }

  if (filtered_data.length <= rule) {
    return;
  }

  let filtered_data_last = last.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );

  if (navigation.sub_category?.name == "Factor overview") {
    filtered_data_last = last;
  }

  if (filtered_data_last.length <= rule) {
    return;
  }

  // iterate on the questions object and filter for each question responses to get the average

  return questions.dimensions[dimension].factors[factor].questions.map(
    (question, idx) => {
      let overall_average = filtered_data
        .map((f) =>
          f.questions
            .filter(
              (f) => f.id == dimension && f.factor == factor && f.q == idx
            )
            .map((q) => q.response)
            .flat()
        )
        .flat();

      let overall_average_last = filtered_data_last
        .map((f) =>
          f.questions
            .filter((f) => {
              return f.id == dimension && f.factor == factor && f.q == idx;
            })
            .map((q) => q.response)
            .flat()
        )
        .flat();
      let name = question.q;
      let average = average_array(overall_average);
      let average_last = average_array(overall_average_last);

      let feedback = filtered_data
        .map((f) =>
          f.feedback
            .filter((f) => f.dimension == dimension && f.factor == factor)
            .map((f) => f.feedback)
        )
        .flat();

      return {
        average,
        standard: average_last,
        name,
        feedback,
      };
    }
  );
};

export const generate_outcome_question_changes = (
  data,
  last,
  navigation,
  outcomeQuestions,
  rule
) => {
  const parent_category = navigation?.category?.id;
  const sub_category = navigation?.factor?.id;

  let filtered_data = data.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );

  if (filtered_data.length <= rule) {
    return;
  }

  let filtered_data_last = last.filter(
    (f) =>
      f.categories.find((f) => f.id === parent_category)?.response ==
      sub_category
  );

  if (filtered_data_last.length <= rule) {
    return;
  }

  return outcomeQuestions?.questions
    ?.find((q) => Number(q?.id) === Number(navigation?.outcome?.id))
    ?.questions.map((question, idx) => {
      let overall_average = filtered_data
        .map((f) =>
          f.employee_outcomes?.responses
            ?.filter(
              (f) =>
                Number(f.q) === Number(question.q) &&
                f?.response &&
                Number(f?.s) == Number(question.s)
            )
            .map((f) => f.response)
        )
        .flat();

      let overall_average_last = filtered_data_last
        .map((f) =>
          f.employee_outcomes?.responses
            ?.filter((f) => {
              return (
                Number(f.q) === Number(question.q) &&
                f?.response &&
                Number(f?.s) == Number(question.s)
              );
            })
            .map((f) => f.response)
        )
        .flat();

      // filter any undefined values
      overall_average = overall_average.filter((f) => f);
      overall_average_last = overall_average_last.filter((f) => f);

      let name = question.question;
      let average = average_array(overall_average);
      let average_last = average_array(overall_average_last);

      let feedback = filtered_data
        .map((f) =>
          f.feedback_builder?.responses
            ?.filter((f) => {
              return Number(f.q) === Number(question.q);
            })
            .map((f) => f.response)
        )
        .flat();

      return {
        average,
        standard: average_last,
        name,
        feedback,
      };
    });
};

function calculateAverage(data) {
  // Utility function to get the reversed score
  function getReverseScore(score) {
      return 10 - score + 1; // Assuming the scores range from 1-10
  }

  const aggregated = {};

  data.forEach(item => {
      const key = `${item.factor}-${item.id}`;
      const score = item.reverse ? getReverseScore(item.response) : item.response;

      if (!aggregated[key]) {
          aggregated[key] = {
              sum: 0,
              count: 0
          };
      }

      aggregated[key].sum += score;
      aggregated[key].count++;
  });

  const averages = [];

  for (const [key, values] of Object.entries(aggregated)) {
      const [factor, dimension] = key.split('-').map(Number);
      averages.push({
          factor: factor,
          dimension: dimension,
          average: values.sum / values.count
      });
  }

  return averages;
}

  // Calculate the average.
  function calculateOutcomeAverage(arr) {

    if (!arr.length) return 0;  // Handle empty arrays
    const sum = arr.reduce((acc, val) => acc + val.response, 0);
    return sum / arr.length;
}


// Get the response average outcome and the factor averages
const get_outcome_and_factor_averages = (data,q) => {
  if(data?.employee_outcomes?.responses?.find(f=>f.q==q)){
    let outcomes = calculateOutcomeAverage(data?.employee_outcomes?.responses?.filter(f=>f.q==q))
    let factor_scores = calculateAverage(data.questions)
    return {outcomes,factor_scores}
  }

  return {outcomes:null,factor_scores:null}
}

function aggregateData(array) {
  const aggregated = {};
  array.forEach(data => {
      data.factor_scores?.forEach(score => {
          const key = `${score.factor}-${score.dimension}`;

          if (!aggregated[key]) {
              aggregated[key] = {
                  factor: score.factor,
                  dimension: score.dimension,
                  average: [],
                  outcome: []
              };
          }

          aggregated[key].average.push(score.average);
          aggregated[key].outcome.push(data.outcomes);
      });
  });

  // Extracting aggregated values to form final result array
  const result = [];
  for (let key in aggregated) {
      result.push(aggregated[key]);
  }

  return result;
}

function calculateMean(arr) {
  return arr.reduce((acc, val) => acc + val, 0) / arr.length;
}

function pearsonCorrelation(arrX, arrY) {
  const meanX = calculateMean(arrX);
  const meanY = calculateMean(arrY);

  const numerator = arrX.reduce((acc, val, idx) => acc + (val - meanX) * (arrY[idx] - meanY), 0);
  const denominator = Math.sqrt(
    arrX.reduce((acc, val) => acc + Math.pow(val - meanX, 2), 0) *
    arrY.reduce((acc, val) => acc + Math.pow(val - meanY, 2), 0)
  );

  if (denominator === 0) return 0; // prevent division by zero

  return numerator / denominator;
}


function processCorrelation(data) {
  const map = {};
  data.forEach(item => {
    const key = `${item.factor}-${item.dimension}`;
    if (!map[key]) {
      map[key] = { average: [], outcome: [] };
    }
    map[key].average.push(...item.average);
    map[key].outcome.push(...item.outcome);
  });

  const results = [];
  for (const [key, value] of Object.entries(map)) {
    const [factor, dimension] = key.split('-').map(Number);
    const correlation = pearsonCorrelation(value.average, value.outcome);
   
    results.push({ factor, dimension, correlation,factor_data:value.average,outcome_data:value.outcome });
  }

  return results;
}

export const calculate_correlations = (data,qId=1) =>{
  let results_arr = [];

  if (data[0] && data[0]?.length > 0) {
    data[0]?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      results_arr = [...results_arr, averages];
    });
  }

  // if data[0] isn't an array, handle the above differently
  if (data[0] && !Array.isArray(data[0])) {
    data?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      results_arr = [...results_arr, averages];
    });
  }

  const aggregate = aggregateData(results_arr.filter((f) => f.outcomes));

  return processCorrelation(aggregate);
}



export const multiple_regression = (data,qId=1,questions) =>{


  let results_arr = [];

 
  if (data[0] && data[0]?.length > 0) {
    data[0]?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      if(averages.outcomes){
        results_arr = [...results_arr, averages];
      }
      
    });
  }
  else{
    data?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      if(averages.outcomes){
        results_arr = [...results_arr, averages];
      }
      
    });
  }

  if(results_arr.length == 0){
    return {'correlation':0,'model':{}}
  }

  const features = results_arr.map(i=>i.factor_scores.map(f=>f.average))
  const labels = results_arr.map(i=>i.outcomes)
  const feature_labels = results_arr[0].factor_scores.map(f=>{
    return {title:questions.dimensions[f.dimension].factors[f.factor].title,
            dimension:f.dimension,factor:f.factor}
    })

  let model = {} 

  // Function to calculate regression coefficients
function calculateCoefficients(X, y) {
  const XMatrix = math.matrix(X);
  const yMatrix = math.matrix(y);
  const XTranspose = math.transpose(XMatrix);
  const XTX = math.multiply(XTranspose, XMatrix);
  const XTXInverse = math.inv(XTX);
  const XTy = math.multiply(XTranspose, yMatrix);
  const coefficients = math.multiply(XTXInverse, XTy);
  return coefficients._data;
}

// Calculate coefficients
const coefficients = calculateCoefficients(features, labels);


// Standardize features
function standardize(features) {
  const means = features[0].map((_, colIndex) => ss.mean(features.map(row => row[colIndex])));
  const stdDevs = features[0].map((_, colIndex) => ss.standardDeviation(features.map(row => row[colIndex])));
  return features.map(row => row.map((value, colIndex) => (value - means[colIndex]) / stdDevs[colIndex]));
}

const standardizedFeatures = standardize(features);

// Calculate coefficients for standardized features
const standardizedCoefficients = calculateCoefficients(standardizedFeatures, labels);

standardizedCoefficients.forEach((coef, index) => {
  model[feature_labels[index].title] = {'coefficient':Math.abs(coef)}
});

// Permutation Importance


function shuffleFeature(data, featureIndex) {
  const shuffledData = data.map(row => [...row]);
  for (let i = shuffledData.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledData[i][featureIndex], shuffledData[j][featureIndex]] = [shuffledData[j][featureIndex], shuffledData[i][featureIndex]];
  }
  return shuffledData;
}

function predict(features, coefficients) {
  return features.map(row => row.reduce((sum, value, index) => sum + value * coefficients[index], 0));
}

function meanSquaredError(actual, predicted) {
  const n = actual.length;
  return actual.reduce((sum, val, idx) => sum + Math.pow(val - predicted[idx], 2), 0) / n;
}


function rSquared(actual, predicted) {
  const mean = ss.mean(actual);
  const totalSumOfSquares = actual.reduce((sum, val) => sum + Math.pow(val - mean, 2), 0);
  const residualSumOfSquares = actual.reduce((sum, val, idx) => sum + Math.pow(val - predicted[idx], 2), 0);
  return 1 - (residualSumOfSquares / totalSumOfSquares);
}

const baselinePredictions = predict(features, coefficients);
const baselineError = meanSquaredError(labels, baselinePredictions);


// Function to calculate residuals
function calculateResiduals(X, y, coefficients) {
  const predictions = predict(X, coefficients);
  return y.map((actual, idx) => actual - predictions[idx]);
}

// Function to calculate Residual Sum of Squares (RSS)
function calculateRSS(residuals) {
  return residuals.reduce((sum, res) => sum + res * res, 0);
}

// Function to calculate the variance of the residuals
function calculateResidualVariance(RSS, n, p) {
  return RSS / (n - p);
}

// Function to calculate the variance-covariance matrix
function calculateVarianceCovarianceMatrix(X, residualVariance) {
  const XMatrix = math.matrix(X);
  const XTranspose = math.transpose(XMatrix);
  const XTX = math.multiply(XTranspose, XMatrix);
  const XTXInverse = math.inv(XTX);
  return math.multiply(XTXInverse, residualVariance);
}

// Function to calculate standard errors of the coefficients
function calculateStandardErrors(varianceCovarianceMatrix) {
  return math.sqrt(math.diag(varianceCovarianceMatrix)._data);
}

// Given data
const n = labels.length; // Number of observations
const p = features[0].length + 1; // Number of coefficients (including intercept)

// Calculate residuals
const residuals = calculateResiduals(features, labels, coefficients);

// Calculate RSS
const RSS = calculateRSS(residuals);

// Calculate variance of the residuals
const residualVariance = calculateResidualVariance(RSS, n, p);

// Calculate variance-covariance matrix
const varianceCovarianceMatrix = calculateVarianceCovarianceMatrix(features, residualVariance);

// Calculate standard errors of the coefficients
const standardErrors = calculateStandardErrors(varianceCovarianceMatrix);


features[0].forEach((_, featureIndex) => {
  const shuffledFeatures = shuffleFeature(features, featureIndex);
  const shuffledPredictions = predict(shuffledFeatures, coefficients);
  const shuffledError = meanSquaredError(labels, shuffledPredictions);
  const importance = shuffledError - baselineError;
  model[feature_labels[featureIndex].title]['importance'] = importance
  model[feature_labels[featureIndex].title]['index'] = feature_labels[featureIndex]
  model[feature_labels[featureIndex].title]['average_score'] = ss.mean(features.map(row=>row[featureIndex]))
  model[feature_labels[featureIndex].title]['SE'] = standardErrors[featureIndex]
});


// Calculate and log the R-squared value
const r2 = rSquared(labels, baselinePredictions);


return {'correlation':Math.sqrt(r2),'model':model}
}

export function calculateOtpCardAverages(
  data,
  previousData,
  structure,
  anchor
) {
  // Helper function to get the score by dimension and factor
  function getScoresByDimension(data, dimensionId, factorId) {
    return data.reduce((acc, person) => {
      const scores = person.questions
        .filter(
          (q) =>
            q.factor === factorId &&
            q.id === dimensionId &&
            q.response !== "N/A"
        )
        .map((q) => q.response);
      return acc.concat(scores);
    }, []);
  }

  // Helper function to calculate average
  function calculateAverage(scores) {
    return scores.length
      ? scores.reduce((a, b) => a + b, 0) / scores.length
      : 0;
  }

  // Helper function to get anchor data
  function getAnchorData(data, anchor) {
    return data.reduce((acc, person) => {
      const anchorValue = person.categories.find(
        (cat) => cat.id === anchor
      )?.response;
      if (anchorValue) {
        acc[anchorValue] = acc[anchorValue] || [];
        acc[anchorValue].push(person);
      }
      return acc;
    }, {});
  }

  // Calculate current and previous scores
  const currentScores = {};
  const previousScores = {};
  structure.dimensions.forEach((dimension) => {
    currentScores[dimension.id - 1] = {};
    previousScores[dimension.id - 1] = {};
    dimension.factors.forEach((factor) => {
      currentScores[dimension.id - 1][factor.id - 1] = calculateAverage(
        getScoresByDimension(data, dimension.id - 1, factor.id - 1)
      );
      if (previousData) {
        previousScores[dimension.id - 1][factor.id - 1] = calculateAverage(
          getScoresByDimension(previousData, dimension.id - 1, factor.id - 1)
        );
      }
    });
  });

  // Calculate overall scores and changes
  const results = structure.dimensions.map((dimension) => {
    const overallScore = calculateAverage(
      Object.values(currentScores[dimension.id - 1])
    );
    const previousOverallScore = calculateAverage(
      Object.values(previousScores[dimension.id - 1])
    );
    const change = overallScore - previousOverallScore;

    const anchorData = Object.entries(getAnchorData(data, anchor)).map(
      ([anchorId, groupData]) => {
        const anchorScores = {};
        const previousAnchorScores = {};
        dimension.factors.forEach((factor) => {
          anchorScores[factor.id - 1] = calculateAverage(
            getScoresByDimension(groupData, dimension.id - 1, factor.id - 1)
          );
          if (previousData) {
            previousAnchorScores[factor.id - 1] = calculateAverage(
              getScoresByDimension(
                previousData,
                dimension.id - 1,
                factor.id - 1
              )
            );
          }
        });
        const anchorOverallScore = calculateAverage(
          Object.values(anchorScores)
        );
        const previousAnchorOverallScore = calculateAverage(
          Object.values(previousAnchorScores)
        );
        const anchorChange = anchorOverallScore - previousAnchorOverallScore;

        return {
          id: anchorId,
          overallScore: anchorOverallScore,
          change: previousData?.length > 0 ? anchorChange : null,
        };
      }
    );

    return {
      id: dimension.id,
      title: dimension.title,
      overallScore: overallScore,
      change: previousData?.length > 0 ? change : null,
      anchorData: anchorData,
    };
  });

  return results;
}

export const calculate_average_outcome = (data,outcomeQ) => {
  const outcome_data = data.map((item) => {
    return item?.employee_outcomes?.responses?.filter(f=>parseInt(f.q)==outcomeQ+1).filter(f=>f?.response).map(f=>f.response)
  })


  return average_array(outcome_data.flat().filter(f=>f))
}


export const calculate_likelihood = (data,qId=1,questions) => {
  
  let results_arr = [];

 
  if (data[0] && data[0]?.length > 0) {
    data[0]?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      if(averages.outcomes){
        results_arr = [...results_arr, averages];
      }
      
    });
  }
  else{
    data?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      if(averages.outcomes){
        results_arr = [...results_arr, averages];
      }
      
    });
  }

  
  let low_score_outcome = 0
  let low_score_count = 0
  let high_score_outcome = 0
  let high_score_count = 0

  results_arr.map((item)=>{
    const culture_score = average(item.factor_scores.map(f=>f.average))
    const outcome_score = item.outcomes

    if(culture_score>8){
      high_score_count += 1
      if(outcome_score>8){
        high_score_outcome += 1
      }
    }

    if(culture_score<6){
      low_score_count += 1
      if(outcome_score>8){
        low_score_outcome += 1
      }
    }

  })

  return {'low_score':low_score_outcome/low_score_count,'high_score':high_score_outcome/high_score_count}


}
function calculateCohensD(mean1, mean2, sd1, sd2, n1, n2) {
  const pooledSD = Math.sqrt(((n1 - 1) * sd1 ** 2 + (n2 - 1) * sd2 ** 2) / (n1 + n2 - 2));
  return (mean1 - mean2) / pooledSD;
}

function calculateStandardDeviation(values) {
  const mean = values.reduce((a, b) => a + b, 0) / values.length;
  return Math.sqrt(values.map(x => (x - mean) ** 2).reduce((a, b) => a + b) / values.length);
}

function calculateStandardErrorCohensD(cohenD, n1, n2) {
  return Math.sqrt((n1 + n2) / (n1 * n2) + (cohenD ** 2) / (2 * (n1 + n2)));
}

function calculateConfidenceInterval(cohenD, se, confidenceLevel = 1.96) { // 1.96 for 95% confidence interval
  const lowerBound = cohenD - confidenceLevel * se;
  const upperBound = cohenD + confidenceLevel * se;
  return { lowerBound, upperBound };
}

function calculateCohensDForFactors(data) {
  const results = [];

  // Get the unique factor/dimension combinations
  const factorDimensionCombinations = data[0].factor_scores.map(f => ({ factor: f.factor, dimension: f.dimension }));

  factorDimensionCombinations.forEach(combo => {
      // Extract the scores for the current factor/dimension combination
      const scoresForCombination = data.map(d => ({
          average: d.factor_scores.find(f => f.factor === combo.factor && f.dimension === combo.dimension).average,
          outcome: d.outcomes
      }));

      // Sort based on outcome scores
      const sortedScores = scoresForCombination.sort((a, b) => a.outcome - b.outcome);
      const q1Index = Math.floor(sortedScores.length * 0.25);
      const q3Index = Math.floor(sortedScores.length * 0.75);

      const bottom25 = sortedScores.slice(0, q1Index + 1);
      const top25 = sortedScores.slice(q3Index);

      const meanBottom = bottom25.reduce((a, b) => a + b.average, 0) / bottom25.length;
      const meanTop = top25.reduce((a, b) => a + b.average, 0) / top25.length;

      const sdBottom = calculateStandardDeviation(bottom25.map(b => b.average));
      const sdTop = calculateStandardDeviation(top25.map(t => t.average));

      const cohenD = calculateCohensD(meanTop, meanBottom, sdTop, sdBottom, top25.length, bottom25.length);
      const standardError = calculateStandardErrorCohensD(cohenD, top25.length, bottom25.length);
      const { lowerBound, upperBound } = calculateConfidenceInterval(cohenD, standardError);

      results.push({
          factor: combo.factor,
          dimension: combo.dimension,
          cohenD,
          lowerBound,
          upperBound
      });
  });

  return results;
}



export const calculate_effect_plot = (data,qId=1,questions) => {
  
  let results_arr = [];

 
  if (data[0] && data[0]?.length > 0) {
    data[0]?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      if(averages.outcomes){
        results_arr = [...results_arr, averages];
      }
      
    });
  }
  else{
    data?.forEach((set) => {
      const averages = get_outcome_and_factor_averages(set, qId);
      if(averages.outcomes){
        results_arr = [...results_arr, averages];
      }
      
    });
  }

  const cohenDResults = calculateCohensDForFactors(results_arr);

  const output = cohenDResults.map((item)=>{
    const factor = questions.dimensions[item.dimension].factors[item.factor].title
    item['factor'] = factor

    return item
  })


  return output.sort((a,b)=>b.cohenD-a.cohenD)

}