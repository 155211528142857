import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  SG_GET_TASK_SCHEDULES,
  SG_GET_TASK_TEMPLATES,
  SG_ADD_TASK_TEMPLATE,
  SG_GET_TASK_TEMPLATE_SELECT,
  CLEAR_TASK_TEMPLATE,
} from "constants/actions";
import { Modal } from "semantic-ui-react";
import Table from "./Table";
import { useNavigate } from "react-router-dom";

const transformData = (data) => {
  // TODO: Do something about the null values.
  return data?.map((result) => {
    return {
      id: result.id,
      name: result.name,
      // created_by: result.created_by,
      // updated_at: result.updated_at,
      status: result.status_text,
      // assignees: result.assigned_employees,
      questions: null,
      created_by: null,
      updated_at: null,
      assignees: null,
    };
  });
};

function parseSurveyData(scheduled, templates) {
  // Step 1: Get a list of scheduled task IDs from the scheduled array
  const scheduledTaskIds = scheduled?.map(
    (item) => item?.task_template_user_tasks
  );

  // Step 2: Filter out any templates that exist in the scheduled task IDs list
  const filteredTemplates = templates?.filter(
    (template) => !scheduledTaskIds?.includes(template?.id)
  );

  // Step 3: Create the "Drafts" array from the filtered templates
  const draftsArray = filteredTemplates?.map((template) => ({
    surveyId: null,
    templateId: template?.id,
    name: template?.name,
    updated_date: template?.updated_at,
    status: 1,
    status_text: "Draft",
    users_count: null,
  }));

  if (!scheduled) {
    return draftsArray;
  }

  // Step 4: Create the "Sent" array from the original scheduled list
  const sentArray = scheduled?.map((task) => ({
    surveyId: task?.id,
    templateId: task?.task_template_user_tasks,
    name: task?.name,
    updated_date: task?.updated_at,
    status: 3,
    status_text: "Sent",
    users_count: task?.assigned_employees?.length,
  }));

  // Step 5: Concatenate the "Sent" array and "Drafts" array
  const resultArray = [...sentArray, ...draftsArray];

  // Sort the drafts array by the updated date
  resultArray.sort((a, b) => {
    return new Date(b.updated_date) - new Date(a.updated_date);
  });

  // Return the final array
  return resultArray;
}

const TaskTemplates = () => {
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState();
  const [selectedTab, setSelectedTab] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [taskCreated, setTaskCreated] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [parsedData, setParsedData] = useState([]);

  const { addToast } = useToasts();
  const navigate = useNavigate();

  const {
    coreData,
    taskTemplates,
    task_templates_select_list,
    created,
    scheduledTasks,
  } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
      taskTemplates: state.task_templates.task_templates,
      task_templates_select_list:
        state.task_templates?.task_templates_select_list,
      created: state.task_templates?.created,
      scheduledTasks: state.task_templates.scheduled_tasks,
    }),
    shallowEqual
  );

  const { reportCreated, created_scheduled_task } = useSelector(
    (state) => ({
      reportCreated: state.task_templates?.reportCreated,
      created_scheduled_task: state.task_templates?.created_scheduled_task,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (reportCreated || created_scheduled_task?.id) {
      dispatch({
        type: CLEAR_TASK_TEMPLATE,
      });
    }
  }, [reportCreated, created_scheduled_task]);

  const addNewTemplate = (template) => {
    const formattedPayload = {
      name: template.name,
      tracked_user_tasks: {
        task_description: template.description,
        task_questions: template?.questions,
      },
      assigned_employees: template.assignees,
    };

    dispatch({ type: SG_ADD_TASK_TEMPLATE, payload: formattedPayload });
    addToast("Template added successfully", { appearance: "success" });
    setSelectedTab(1);
  };

  useEffect(() => {
    if (taskTemplates?.results?.length > 0) {
      const parsed = parseSurveyData(
        scheduledTasks?.results,
        taskTemplates?.results
      );
      setParsedData(parsed);
    }

    if (taskTemplates?.results?.length === 0) {
      navigate("/leader/audit/manage/tasks/create");
    }
  }, [taskTemplates, scheduledTasks, navigate]);

  useEffect(() => {
    if (created) {
      dispatch({ type: SG_GET_TASK_TEMPLATE_SELECT });
    }
  }, [created, dispatch]);

  useEffect(() => {
    dispatch({ type: SG_GET_TASK_TEMPLATE_SELECT });
    dispatch({ type: SG_GET_TASK_SCHEDULES });
    dispatch({ type: SG_GET_TASK_TEMPLATES });
  }, []);

  useEffect(() => {
    if (
      taskTemplates?.results?.length > 0 &&
      task_templates_select_list?.length > 0
    ) {
      setTemplates(transformData(task_templates_select_list));
    }
  }, [taskTemplates, task_templates_select_list]);

  // Create a function to edit a template
  // it should take the template object as an argument
  // and update the template with the same id in the templates array
  const editTemplate = (template) => {
    addToast("Template updated successfully", { appearance: "success" });
  };

  return (
    // Create a simple placeholder component that displays the text "Task Templates"
    // it should also contain a tab conditionally rendering either the List or New component
    <Container>
      <UpdateModal
        edit={(template) => editTemplate(template)}
        open={updateModal}
        setOpen={setUpdateModal}
        template={selectedTemplate}
        coreData={coreData}
      />
      <Center>
        <TopRow>
          <Title>Custom Surveys</Title>
          {(parsedData?.length > 0 ||
            (taskTemplates?.length === 0 &&
              scheduledTasks?.results?.length === 0)) && (
            <CreateButton
              onClick={() => {
                navigate("/leader/audit/manage/tasks/create");
              }}
            >
              Create New
            </CreateButton>
          )}
        </TopRow>
        <Table data={parsedData} />
      </Center>
    </Container>
  );
};

const UpdateModal = ({ open, setOpen, template, edit, coreData }) => {
  const [name, setName] = useState(template?.name);
  const [description, setDescription] = useState(template?.description);
  const [questions, setQuestions] = useState(template?.questions);
  const [assignees, setAssignees] = useState(template?.assignees);

  const handleUpdate = () => {
    edit({
      id: template?.id,
      name,
      description,
      questions,
      assignees,
    });
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)} size="small">
      <Modal.Header>Edit Template</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Title>Name</Title>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name"
          />
          <Title>Description</Title>
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter description"
          />
          <Title>Questions</Title>
          <Questions
            questions={questions}
            setQuestions={setQuestions}
            assignees={assignees}
          />
          <Title>Assignees</Title>
          <Assignees
            coreData={coreData}
            assignees={assignees}
            setAssignees={setAssignees}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleUpdate}>Update</Button>
      </Modal.Actions>
    </Modal>
  );
};

const Assignees = ({ assignees, setAssignees, coreData }) => {
  const [selected, setSelected] = useState();

  const handleSelect = (employee) => {
    if (selected === employee) {
      setSelected(null);
      setAssignees(assignees.filter((a) => a.id !== employee.id));
    } else {
      setSelected(employee);
      setAssignees([...assignees, employee]);
    }
  };

  return (
    <AssigneeContainer>
      {coreData?.employees?.map((employee) => (
        <Assignee
          key={employee.id}
          selected={selected?.id === employee.id}
          onClick={() => handleSelect(employee)}
        >
          {employee.first_name} {employee.last_name}
        </Assignee>
      ))}
    </AssigneeContainer>
  );
};

const Questions = ({ questions, setQuestions, assignees }) => {
  const [question, setQuestion] = useState("");
  const [type, setType] = useState("text");

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question,
        type,
        aiEnhanced: type === "ai",
      },
    ]);
    setQuestion("");
  };

  return (
    <QuestionContainer>
      <Input
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder="Enter question"
      />
      <Select value={type} onChange={(e) => setType(e.target.value)}>
        <option value="text">Text</option>
        <option value="ai">AI</option>
      </Select>
      <Button onClick={addQuestion}>Add</Button>
      {questions?.map((q, index) => (
        <Question key={index}>
          {q.question} - {q.type}
        </Question>
      ))}
    </QuestionContainer>
  );
};

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const Question = styled.div`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
`;

const Select = styled.select`
  font-family: "Inter", sans-serif !important;
  border-radius: 20px !important;
  font-size: 10px !important;
  color: #7e7e7e;
`;

const CreateButton = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

const AssigneeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const Assignee = styled.div`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#2d70e2" : "white")};
  color: ${(props) => (props.selected ? "white" : "#2d70e2")};
  border: 1px solid #2d70e2;
`;

const TextArea = styled.textarea`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
  height: 100px;
  border-radius: 5px;
  padding: 10px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Input = styled.input`
  font-family: "Raleway";
  font-size: 14px;
  color: #2a3039;
  margin-top: 10px;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  margin-top: 0px;
  animation: ${fadeIn} 0.3s;
  padding:20px;
  display:flex;
  justify-content:center;
`;

const Center = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
`;

const Button = styled.div`
  color: white;
  background-color: #2d70e2;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-family: "Raleway";
  border-radius: 8px;
`;

export default TaskTemplates;
