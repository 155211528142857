import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Icon,Popup } from "semantic-ui-react";

import { Link, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { NavData } from "./navigation";

import { pathList } from "routes/routeMap";
import { Domain } from "_config";

import {
  LOAD_EMPLOYEES,
  SG_FETCH_USER_EMPLOYEE,
  PULL_ORGANIZATION,
  SET_WHITE_LABEL,
  SG_GET_EMPLOYEE_PERMISSIONS,
} from "constants/actions";

import { DomainContext } from "layouts/LayoutContext";

import { white_label_data } from "data/white_label";

import InnerLogicLogo from "assets/images/full-logo.svg";
import HAM from "assets/images/nav/hamburger.svg";
import useGetUserSettings from "hooks/useGetUserSettings";
import useGetUserPersona from "hooks/useGetUserPersona";
import Languages from "language/index";
import { useLanguageSetting } from "language/redux_store";
import Settings from "assets/images/nav/cog.svg";
import Compass from "assets/images/nav/compass.svg";
import Logout from "assets/images/nav/logout.svg";

const SubNav = (props) => {

  return (
    <Link to={props.data.link}>
      <Sub>
        <SubText selected={props.selected}>{props.data.title}</SubText>
      </Sub>
    </Link>
  );
};

const NavItem = (props) => {
  const { data, open, selected, name, highlight, isFrench,read_feedback,required_recommendation,accountId,demo,beta } = props;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const subSelected =
    data?.subNav?.map((item) => item.link).includes(selected) ||
    data?.subNav
      ?.map((item) => item.link)
      .includes(selected?.replace("/create", ""));

  return (
    <NavContainer
      onMouseEnter={() => setOpenSubMenu(true)}
      onMouseLeave={() => setOpenSubMenu(false)}
      open={selected.includes(data.link)}
    >
      <Link to={data.link} style={{ width: "100%" }}>
        <IconContainer
          selected={selected.includes(data.link)}
          highlight={highlight}
          border={data.border}
          open={open}
        >
          {data.svg ? (
            data.svg(selected.includes(data.link) ? "#2D70E2" : "#666D79")
          ) : data.image ? (
            <IconImage src={data.image} />
          ) : (
            <Icon
              name={data.icon}
              size={"small"}
              style={{ width: 40, fontSize: 15, margin: 0, marginLeft: -5 }}
            />
          )}

          <Text selected={selected.includes(data.link)} open={open}>
            {name
              ? name
              : isFrench()
              ? data.fr
                ? data.fr
                : data.title
              : data.title}
          </Text>
        </IconContainer>
      </Link>

      <SubNavContent>
        {data.subNav
          ? data.subNav.map((x, id) => {
              if (
                x.require_feedback_access &&
                !read_feedback &&
                accountId !== 5
              ) {
                return null;
              }
              if (
                x.required_recommendation &&
                !required_recommendation &&
                accountId !== 5
              ) {
                return null;
              }
              if (!demo && x.demo) {
                return null;
              }
              if(!beta && x.beta){
                return null;
              }
              return (
                <SubNav
                  data={x}
                  key={id}
                  selected={
                    selected == x.link ||
                    (!subSelected && id == 0) ||
                    selected.replace("/create", "") === x.link
                  }
                />
              );
            })
          : ""}
      </SubNavContent>
    </NavContainer>
  );
};

const SideBar = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const Domain = useContext(DomainContext);
  const language_hook = new Languages(useLanguageSetting());

  const location = useLocation();
  const [selected, setSelected] = useState(null);

  const [accountId, setAccountId] = useState(1);
  const [roleId, setRoleId] = useState(0);
  const [employee, setEmployee] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [validRoutes, setValidRoutes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFullMenu, setShowFullMenu] = useState(false);

  const [organizationLogo, setOrganizationLogo] = useState(Domain.image);

  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );
  const AmbassadorId = useSelector(
    (state) => state.auth?.ambassador_id,
    shallowEqual
  );
  const WhiteLabel = useSelector(
    (state) => state.white_label?.white_label,
    shallowEqual
  );

  const {
    get_auth,
    get_employees,
    get_organizations,
    get_selectedOrg,
    get_employee_permission,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
      get_organizations: state.organizations,
      get_selectedOrg: state.selectedOrg,
      get_employee_permission: state.employee_permission,
    }),
    shallowEqual
  );

  const {
    isOtp,
    currentUser: userCat,
    filteredCategories,
  } = useGetUserSettings();

  const { pdfFeatureEnabled } = useGetUserPersona({
    user: userCat,
    isOtp,
    filteredCategories,
  });

  // Use this to determine PDF access
  // console.log(pdfFeatureEnabled);

  useEffect(() => {
    // if the response is ok 200
    if (Number(get_auth?.organization_id) > 0) {
      dispatch({
        type: PULL_ORGANIZATION,
        payload: { id: get_auth.organization_id },
      });
    }
    dispatch({ type: LOAD_EMPLOYEES }); // from server
    // dispatch({ type: LOAD_TEAMS });
    dispatch({
      type: SET_WHITE_LABEL,
      payload: white_label_data["Nuwave"],
    });
  }, [dispatch, get_auth]);

  useEffect(() => {
    setOrganizationLogo(organization?.logo);
  }, [organization]);

  useEffect(() => {
    if (get_auth?.token) {
      if (Number(EmployeeId)) {
        setAccountId(get_employees?.userEmp?.account_type);
        setRoleId(get_employees?.userEmp?.role);

        setEmployee(get_employees.userEmp);
        setOrganization(get_organizations[get_auth.organization_id]);
      }
      if (Number(AmbassadorId)) {
        setAccountId(5);
        setRoleId(5);
        // setEmployee(get_employees[get_auth.employee_id])
        setOrganization(get_selectedOrg?.organization);
      }
      // console.log(get_employees[get_auth.employee_id])
    }
  }, [
    get_employees,
    get_auth,
    get_organizations,
    AmbassadorId,
    EmployeeId,
    get_selectedOrg,
  ]);

  useEffect(() => {
    dispatch({
      type: SG_GET_EMPLOYEE_PERMISSIONS,
      payload: `employee=${Number(EmployeeId)}`,
    });
  }, [EmployeeId]);

  useEffect(() => {
    if (organization?.styling) {
      dispatch({
        type: SET_WHITE_LABEL,
        payload: organization.styling,
      });
    }
  }, [organization, dispatch]);

  useEffect(() => {
    const currentPath = location.pathname;
    const taskViewRegex = /\/leader\/audit\/manage\/task\/\d+/;
    const taskQuestionRegex =
      /\/simple\/audit\/manage\/questionnaire\/[a-zA-Z0-9-]+/;
    if (taskViewRegex.test(currentPath)) {
      // hack fix for highlighted item on tasks.
      setSelected("/leader/audit/manage/tasktemplates");
      return;
    }

    if (taskQuestionRegex.test(currentPath)) {
      // hack fix for highlighted item on tasks.
      setSelected("/leader/audit/manage/tasks");
      return;
    }

    setSelected(currentPath);
  }, [location]);

  useEffect(() => {
    const EnabledList = [];
    pathList.map((path) => {
      let Found = organization?.services_enabled?.filter(
        (srv) => srv?.name === path?.name && srv?.enabled === true
      )?.[0];
      if (Found?.id > 0) {
        EnabledList.push(path.name);
      }
      return null;
    });
    EnabledList.push("Common");

    setValidRoutes(EnabledList);

    const timer1 = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization]);

  useEffect(() => {
    // role < 7
    // account_type < 9
    setShowFullMenu(false);
    if (validRoutes.includes("Teamtool")) {
      if (employee?.account_type < 9) {
        setShowFullMenu(true);
        return;
      }
      if (employee?.role < 7) {
        setShowFullMenu(true);
        return;
      }
    }
  }, [employee, validRoutes]);

  const check_employee_permissions= (permissions) => {
    if(!permissions?.employee_permission?.length > 0){
      return false
    }
    if(!permissions?.employee_permission[0]?.details?.length>0){
      return false
    }
    return true
  }



  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999,
        backgroundColor: "white",
      }}
    >
      <Bar
        open={open}
        // onMouseEnter={() => setOpen(true)}
        // onMouseLeave={() => setOpen(false)}
        background={"white"}
        grains={
          organization?.styling?.nav_background
            ? organization?.styling?.nav_background
            : false
        }
      >
        <LogoContainer>
          {/* {open ? <Ham src={HAM} onClick={() => setOpen(!open)} /> : ""} */}
          {WhiteLabel?.logo ? <Logo src={WhiteLabel?.logo} /> : <Logo src={InnerLogicLogo} />}
        </LogoContainer>

        <Content>
        {NavData.map((item, i) => {
          if (!validRoutes.includes(item.group)) {
            return null;
          }

          if (Number(AmbassadorId)) {
            if (item.hideForAmbassador) {
              return null;
            }
          }

          {
            /*          if (organization?.days_for_trial < 0 && item.paid) {
            return null;
          }

          if (organization?.payment_status > 8 && item?.paid) {
            return null;
          }*/
          }

          // no much of value of roles
          // outside of team tool
          if (accountId !== 5) {
            if (item.role < roleId && validRoutes.includes("Teamtool")) {
              return null;
            }
          }

          if (item.admin && accountId !== 5) {
            return null;
          }

          if (item.team && item.title !== "My Data") {
            if (roleId > 10) {
              return null;
            }
          }

          if (item.personality) {
            // console.log(item)
            if (!employee?.personality) {
              return null;
            }
          }

          if (item.leadership) {
            // console.log(item)
            if (!employee?.leadership) {
              return null;
            }
          }

          // donot change the order this has to come last
          // if you change the order then leadership
          // and personalisty checks would get skipped
          if (!showFullMenu) {
            if (item.group !== "Teamtool" && validRoutes.includes("Teamtool")) {
              return null;
            }
          }

          if (item.header) {
            return null;
          }

          if (roleId < 9 && item.title === "My Data") {
            return (
              <NavItem
                data={item}
                organization={organization}
                selected={selected}
                open={open}
                key={i}
                name="Member Analytics"
                highlight={"#EBF1FD"}
              />
            );
          }


          if (WhiteLabel?.survey_logo && item.innerlogic_only) {
            return null;
          }

          if(!WhiteLabel?.survey_logo && item.not_for_innerlogic){
            return null;
          }


          if(!WhiteLabel?.demo && item.demo){
            return null;
          }

         

          if (WhiteLabel?.subfactor && item.no_subfactor) {
            return null;
          }

          if (!WhiteLabel.demo && item.demo) {
            return null;
          }

          if (
            item.require_access_data &&
            !employee?.access_data_analytics &&
            accountId !== 5
          ) {
            return null;
          }

          if (
            item.require_access_pdf &&
            !pdfFeatureEnabled &&
            accountId !== 5
          ) {
            return null;
          }
          if (
            item.require_add_users &&
            !employee?.survey_add_users &&
            accountId !== 5
          ) {
            return null;
          }

          if(item.require_calendar && !employee?.survey_schedule && accountId !== 5){
            return null
          }

          if (
            item.require_feedback_access &&
            !employee?.read_feedback 
            &&
            accountId !== 5
          ) {
            return null;
          }
         
          if(check_employee_permissions(get_employee_permission) && item.employee_permission){
            return null
          }

          return (
            <NavItem
              data={item}
              organization={organization}
              selected={selected}
              open={open}
              key={i}
              highlight={"#EBF1FD"}
              isFrench={() => language_hook.is_french()}
              read_feedback={employee?.read_feedback}
              accountId={accountId}
              demo={WhiteLabel.demo}
              beta={WhiteLabel.beta}
              required_recommendation={employee?.employee_employee_permission?.auth_details?.find(f=>f.value=="access_recommendations")?.response}
            />
          );
        })}
        </Content>

        <Bottom>


        
          {/* <Link to="/settings/member" >
          <BottomLink>
            <Image src={Settings} adjust={true}/>
            <Text>Profile</Text>
            </BottomLink>
            </Link> */}
       
        
        
        <Link to="/account/logout">
          <BottomLink>
          <Image src={Logout} />
          <Text>Logout</Text>
          </BottomLink>
        </Link>
        
       
        </Bottom>
        
      </Bar>
    </div>
  );
};

export default SideBar;
// aR8th"cd6"#8U!*Y

const Bar = styled.div`
  width: 200px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  box-sizing: content-box;
  position: relative;
  transition: width 400ms ease;
  padding-top: 10px;
  background-color: ${(props) => props.background};
  min-height: 100vh;
  height:100vh;
  overflow: hidden;
  background-color: #F8FAFF;
`;

const Logo = styled.img`
  width: 120px;
  margin-left: 5px;
`;

const LogoContainer = styled.div`
  font-size: 35px;
  width: 175px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:12px;
`;

const Avatar = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  bottom: 67px;
`;
const IconContainer = styled.div`
  color: white;
  font-size: 25px;
  width: 205px;
  background-color: ${(props) => (props.selected ? props.highlight : "none")};
  padding-left: ${(props) => (props.open ? "15px" : "18px")};
  display: flex;
  align-items: center;

  flex-direction: row;
  margin-top: 0px;
  height: 40px;
  border-top: ${(props) => (props.border ? "1px solid #DFDFDF" : "")};

  transition: all 0.5s;
`;

const Text = styled.div`
  font-family: "Raleway", sans-serif;
  font-size: 11px;
  margin-left: 15px;
  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-weight:400;
`;

const SubText = styled.div`
font-family: "Raleway", sans-serif;
  font-size: 11px;
  margin-bottom:5px;
  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-weight:${props=>props.selected? 'bold':400};
  `

const Innerlogic = styled.div`
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
  color: white;
`;

const Sub = styled.div`

`;

const NavContainer = styled.div`
  position: relative;
  min-height: 40px;
  max-height:${props=>props.open?'300px':'40px'};
  overflow: hidden;
  transition: max-height 1s ease;
  width:calc(100% - 20px);
  margin-left:10px;
  display:flex;
  flex-direction:column;
  align-items:center;
  border-radius: 5px;
`;

const Expand = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 300ms;
  cursor: pointer;
  color: white;
`;

const IconImage = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 6px;
`;

const Header = styled.div`
  color: white;
  font-size: 12px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: bold;
  width: 50px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Ham = styled.img`
  margin-right: 120px;
  cursor: pointer;
`;

const Content = styled.div`
  margin-top:30px;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 20px;
`;

const Image = styled.img`
  height: 19px;
  width: 19px;
  margin-left:${props=>props.adjust?'-5px':'0px'};
`;

const BottomLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  margin-bottom: 20px;
`;

const SubNavContent = styled.div`
  display:flex;
  flex-direction:column;
`