import ClickAwayListener from "react-click-away-listener";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";

const Dropdown = ({ options, setSelected, label, selectedOption }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [selected, setSelectedOption] = useState(null);

  const selectOption = (option) => {
    setOpen(false);
    setSelected(option?.value);
    setSelectedOption(option);
  };

  const clearSelection = () => {
    setSelected(null);
    setSelectedOption(null);
    setTimeout(() => {
      setOpen(false);
    }, 1);
  };

  useEffect(() => {
    if (selectedOption) {
      setSelectedOption(
        options.find((option) => option.value === selectedOption)
      );
    } else {
      setSelectedOption(null);
    }
  }, [selectedOption]);

  // Calculate the width based on the longest option
  const getMaxOptionWidth = () => {
    return (
      options.reduce((max, option) => {
        const optionLength = option.name.length;
        return optionLength > max ? optionLength : max;
      }, 0) * 8
    ); // assuming average character width is 8px
  };

  return (
    <Box>
      <Label>{label}</Label>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <DropdownContainer
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          width={getMaxOptionWidth()}
        >
          <Nav onClick={() => setOpen(!open)}>
            <Selection>{selected?.name || "Select an option"}</Selection>
            <Icon
              name="triangle down"
              style={{ marginTop: -4, color: "#B3B3B3" }}
            />
            {selected && (
              <ClearButton onClick={clearSelection}>
                <Icon name="times" style={{ color: "#2D70E2" }} />
              </ClearButton>
            )}
          </Nav>
          {open && (
            <DropdownContent width={getMaxOptionWidth()}>
              {options.map((option, i) => (
                <Option key={i} onClick={() => selectOption(option)}>
                  {option.name}
                </Option>
              ))}
            </DropdownContent>
          )}
        </DropdownContainer>
      </ClickAwayListener>
    </Box>
  );
};

const Label = styled.div`
  font-size: 12px;
  color: #2a3039;
  margin-right: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: -20px;
`;

const DropdownContainer = styled.div`
  position: relative;
  border: 1px solid lightgrey;
  padding: 5px;
  width: ${({ width }) => width}px;
  max-width: 800px;
`;

const Nav = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Selection = styled.div`
  font-size: 14px;
  color: #2a3039;
  margin-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
  &:focus {
    outline: none;
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 100;
  width: ${({ width }) => width}px;
  max-width: 800px;
`;

const Option = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  color: #2a3039;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export default Dropdown;
